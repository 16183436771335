$primary: #040000;
@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700,900');

$family-sans-serif: "Nunito", sans-serif;
$break-small-laptop: 1215px;
$break-medium: 1023px;
$break-small: 768px;
$footer-padding: 3rem 1.5rem;
$breadcrumb-item-padding-vertical: 1rem;
$table-background-color: #fbfbfb;
$table-row-hover-background-color: #f0f0f0;
$list-item-hover-background-color: white;

// Import of bulma components
@import "bulma";


html, html body {
  color: black;
  font-family: 'Nunito', sans-serif;
  background: #fbfbfb;
}

.button.is-primary.is-light{
  color: $primary !important;
}
.tag:not(body).is-primary.is-light{
  color: $primary !important;
}

.App{
  background-color: white;
  @media screen and (max-width: $break-small) {
    overflow: hidden;
  }
}

.container {
  @media screen and (min-width: 1408px) {
    max-width: 1152px !important;
  }
  @media screen and (max-width: $break-medium) {
    padding-left: 1em;
    padding-right: 1em;
  }
  @media screen and (max-width: $break-small) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.main-container {
  @media screen and (min-width: $break-medium) {
    height: calc(100vh - 40px);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.main-box {
  padding: 0;
  background: #fbfbfb;
  @media screen and (min-width: $break-medium) {
    height: 100%;
    overflow-y: auto;
  }
}
.map-section-wrapper{
  box-shadow: 0 5px 1em 0.2em rgba(10,10,10,0.2), 0 0 0 1px rgba(10,10,10,0.02);
  border-radius: 4px;
  margin-bottom: 1em;
  #map{
    height: 300px;
    border-radius: 4px;
    @media screen and (max-width: $break-medium) {
      height: 200px;
    }
  }
}
nav.navbar{
  box-shadow: 0 2px 0 0 whitesmoke;
  &> .container {
    display: flex !important;
  }
  .navbar-brand{
    .navbar-item{
      margin-top: -1em;
      margin-bottom: -1em;
      padding-right: 0;
      @media screen and (max-width: $break-medium) {
        padding-left: 0;
      }
      img{
        max-height: 3.75rem;
        @media screen and (max-width: $break-medium) {
          max-height: 2.75rem;
        }
        @media screen and (max-width: $break-medium) {
          max-width: 120px;
        }
      }
    }
  }
  .navbar-end{
    justify-content: flex-end;
    margin-left: auto;
    .navbar-item{
      padding: 0.5em;
      @media screen and (max-width: $break-small) {
        padding: 0.5em 0.3em;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

#options-modal{
	.modal-content{
		width: 460px;
		@media screen and (max-width: $break-small) {
		    width: 100%;
		    max-height: 100vh;
		    .box{
			    padding-top: 3em;
				border-radius: 0;
		    }
		}
	}
	@media screen and (max-width: $break-small) {
		.modal-close{
			background-color: lightgray;
		}
    .modal-close::before{
      background: black;
      height: 3px;
    }
    .modal-close::after {
      width: 3px;
      background: black;
    }
	}
  .extra-group{
    margin-bottom: 1em;
  }
}

#cart-notification{
  position: fixed;
  width: 300px;
  top: 95px;
  right: 15px;
  z-index: 1000;
  transform: translateX(1500%);
  -webkit-transform: translateX(150%);
  transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
  &.is-active{
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  @media screen and (max-width: $break-small) {
    top: 75px;
  }
}

#mobile-filter-tags {
	position: fixed;
  left: 0;
  right: 0;
  z-index: 7;
  top: 53px;
  width: 100%;
  height: 62px;
  display: flex;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background: white;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 0 2px 0 0 whitesmoke;
  .mobile-filter-wrapper{
    margin-top: -0.25em;
    margin-bottom: -0.5em;
    .tags {
      flex-wrap: nowrap;
    }
  }
}


#mobile-filter-box {
  @media screen and (max-width: $break-small) {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 250ms cubic-bezier(0.165,0.84,0.44,1);
    background-color: white;
    left: 0;
    padding: 0.5em;
    &.is-active {
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
    .box{
      box-shadow: none;
      padding: 0;
    }
  }
}

#pickup-button,
#delivery-button {
  &.is-active{
    background-color: #eeebfe;
    color: #3717f7;
  }
}

#planned-delivery,
#tip-percentage-div,
#shipping-address,
#tip-div,
#shipping-address-step2,
#pickup-address-step2 {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  -moz-transition: max-height 0.15s ease-in-out;
  -o-transition: max-height 0.15s ease-in-out;
  -webkit-transition: max-height 0.15s ease-in-out;
  overflow: hidden;
  &.appear{
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
  }
}

.sticky{
  @media screen and (min-width: $break-small) {
    position: sticky;
    top: 70px;
  }
  @media screen and (min-width: $break-medium) {
    position: sticky;
    top: 110px;
  }
}

.select {
  width: 100%;
  select {
      width: 100%;
  }
}

.box-content {
  padding-top: 110px;
  @media screen and (min-width: $break-medium) {
    min-height: 84vh;
  }
  @media screen and (max-width: $break-medium) {
    padding-top: 70px;
  }
}

h1{
  font-weight: 900 !important;

  @media screen and (max-width: $break-medium) {
    font-size: 1.6em !important;
  }
}

.hero-category {
  padding-bottom: 15px;
  margin-bottom: 17px;
	box-shadow: 0 2px 0 0 whitesmoke;
  @media screen and (max-width: $break-small) {
    margin-top: 3.5em;
  }
	h1{
		margin-bottom: 0!important;
	}
	.tags{
		margin-top: 15px;
	}
  img{
    max-height: 210px;
    border-radius: 6px;
    width:100%;
    object-fit: cover;
    @media screen and (max-width: $break-small) {
        height: 180px;
    }
  }
}
.delays-n-prices{
  padding-top: 15px;
  margin-top: 17px;
	box-shadow: 0 -2px 0 0 whitesmoke;
}
.loading {
  overflow: hidden;
  position: relative;
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(200, 200, 200, .4), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.location-card {
	border-radius: 7px;
	min-height: 130px;
	padding: 1em;
	
	display: flex;
  flex: 5 1 0%;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  break-inside: avoid;
  text-align: left;
    
	@media screen and (max-width: $break-small) {
    padding: 0.5em;
	  min-height: 100px;
  }
    
	.title{
		margin-bottom: 0;
    font-weight: 900;
	}
	
	.card--img{
		width: 30%;
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	.card-image{
		width: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $break-small) {
			width: 30%;
	    }
		.image-container {
			border-radius: 4px;
			border: 1px solid rgb(225, 229, 232);
			height: 100px;
			width: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.3rem;
		}
		img {
			max-width: 90px;
			border-radius: 4px;
		}
	}
	.card-content{
		width: 70%;
		display: flex;
		justify-content: center;
		padding: 0 0.5rem;
		flex-direction: column;
	}
	.card-button{
		width: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
    font-size: 3.5em;
    color: $primary;
		@media screen and (max-width: $break-small) {
			display: none;
	    }
	}
	hr {
		margin: 0 0 1em 0;
	}
	.excerpt {
		margin-bottom: 1em;
	}
	.tags{
		justify-content: center;
		.tag {
			padding-top: 5px;
			padding-bottom: 5px;
			height: auto;
	    	min-width: 150px;
			box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
		}
	}
}
.is-fullwidth{
  width: 100%;
}
.gradiant {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}
.clickable-link{
	color: #2e0df7;
	text-decoration: underline;
	cursor: pointer;
}

.payment-text{
	img{
		max-height: 20px;
		vertical-align:middle;
		margin-left: 5px;
	}
}

.StripeElement {
  width: 100%;
	border: 1px solid transparent;
	background-color: #fbfbfb;
	-webkit-box-shadow: 0 0 0 0.5px rgba(50,50,93,.1), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07);
  box-shadow: 0 0 0 0.5px rgba(50,50,93,.1), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07);
  padding: 7px;
  border-radius: 4px;
}
.box-summary{
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  .product{
    border-bottom: 1px dashed #f2f2f2;
    margin-bottom: 1em;
  }
  #summary-body{
    padding: 0 0.5em 1em;
  }
  #payment-buttons-div{
    padding: 0.5em;
  }
  @media screen and (min-width: $break-small) {
		max-width: 450px;
		margin-right: 0;
    overflow: hidden;
    flex: 1 1 auto;
    max-height: 65vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    #summary-header{
      padding: 1em;
    }
    #summary-body{
      overflow-y: auto;
      flex: 1 1 auto;
      overflow-x: hidden;
      padding: 0 1em 1em;
    }
    #payment-buttons-div{
      border-top: 1px solid #F1F2F4;
      position: relative;
      margin-top: -1px;
      flex: 0 0 auto;
      padding: 1em;
      box-shadow: 0 0 0 0.5px rgba(50,50,93,.1), 0 -6px 7px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07);
    }
  }
	.checkout-products-container{
		.box{
			margin: 10px;
		  @media screen and (max-width: $break-small) {
				margin: 5px;
		  }
		}
		.columns{
			overflow: hidden;
		}
	}

  #checkout-total{
    .columns{
      margin-bottom: 0;
    }
  }
}
.panel{
  background-color: white;
}
#checkout-columns{
	max-width: 600px;
	margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $break-small) {
    #checkout-summary{
      display: flex;
      flex-direction: column;
        #promo-code-div {
          order: 2;
          margin-bottom: 1.5rem;
        }
        #summary-div {
          order: 1;
          margin-bottom: 1.5rem;
        }
        #consigne-div {
          order: 3;
          margin-bottom: 0;
        }
    }
  }
}

.tip-choice{
	height: 65px;
	width: 85px;
	border-radius: 10px;
	background-color: white;
  border: 1px solid #dbdbdb;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
  background-color: #eeebfe;
    color: #3717f7;
    border-color: transparent;
  }
  @media screen and (max-width: $break-small) {
  height: 70px;
  width: 65px;
  }
  &.active{
  background-color: #eeebfe;
    color: #3717f7;
    border-color: transparent;
  }
}
.prev-next-buttons{
	@media screen and (max-width: $break-small) {
		position: fixed;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    background: white;
	    padding: 6px;
	    z-index: 100;
	    border-top: 1px solid lightgrey;
	}
	&#free-payment-div{
		width: 100%;
		text-align: center;
		@media screen and (max-width: $break-small) {
			height: 105px;
		}
	}
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: gray;
}

::placeholder {
  color: gray;
}

.product-card{
	cursor: pointer;
  border-radius: 7px;
  overflow: hidden;
	height: 135px;
	max-height: 135px;
	
	display: flex;
  flex: 5 1 0%;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  break-inside: avoid;
  text-align: left;
  transition: border-color 0.25s ease 0s;
    
	.columns{
		height: 100%;
	}
	.card-content{
		padding: 1.2rem;
    @media screen and (max-width: $break-small) {
      padding: 1rem;
    }
	}
	.card-content.has-img{
	    width: 70%;
	} 
	.product-title{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
  }
  .product-description {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		max-height: 40px;
		white-space: normal;
	}
	.card-product-img{
		width: 30%;
		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.placeholder-item {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  min-height: 20px;
  min-width: 150px;
  color: transparent;
  background-color: #ddd;
  opacity: 0.8;
  &.sm{
    min-width: 30px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    width: 150px;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #E8C2E5 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    @media screen and (max-width: $break-small) {
      background: linear-gradient(to right,transparent 0,#e8e8e8 40%,transparent 0%);
    }
  }
  &.revert{
    box-shadow: none;
    background-color: #fbfbfb;
    &::before {
      background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    }
  }
}

#filter-box {
	position: sticky;
	top: 10px;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}